<template>
    <div class="col-xl-6 col-lg-6 col-md-6 order-clg-2">
        <div v-html="page"></div>
        <!-- Не трогать! -->
        <div
            v-if="
            courseType !== 'technical' &&
            courseType !== 'supervisorNsto' &&
            courseType !== 'masterEducation'
          "
        >
            <div class="education__detail-description-list-title">
                Образцы сертификатов
            </div>
            <div class="education__detail-description">
                <div
                    class="education__detail-description-text"
                    v-if="groupUrl || courseId === 'marketing-coaching-1'"
                >
                    Участники получают именные сертификаты по итогам каждого пройденного
                    курса
                </div>
                <div class="education__detail-description-text" v-else>
                    Участники проходят входное и итоговое тестирование. По итогам
                    тестирования компании-дилеру присваивается грейд и выдается
                    сертификат:
                    <div class="mt-3">1 уровень – начальный</div>
                    <div>2 уровень – базовый</div>
                    <div>3 уровень – продвинутый</div>
                </div>
            </div>
            <div
                class="education__detail-gallery"
                v-if="groupUrl || courseId === 'marketing-coaching-1'"
            >
                <div
                    class="education__detail-gallery-img"
                    v-for="(image, key) in imageNew"
                    :key="key"
                >
                    <img
                        alt=""
                        class="item"
                        :src="image"
                        @click="
                  selectImage(image, key);
                  show();
                "
                    />
                    <img
                        class="education__detail-gallery-icon"
                        @click="
                  selectImage(image, key);
                  show();
                "
                        src="../../assets/images/lupa.svg"
                    />
                </div>
            </div>
            <div class="education__detail-gallery" v-else>
                <div
                    class="education__detail-gallery-img"
                    v-for="(image, key) in image"
                    :key="key"
                >
                    <img
                        alt=""
                        class="item"
                        :src="image"
                        @click="
                  selectImage(image, key);
                  show();
                "
                    />
                    <img
                        class="education__detail-gallery-icon"
                        @click="
                  selectImage(image, key);
                  show();
                "
                        src="../../assets/images/lupa.svg"
                    />
                </div>
            </div>
        </div>
        <div class="education__button">
            <router-link
                custom
                :to="{
                          name: 'Education_request',
                          params: {
                            id: courseId,
                          },
                    }"
                class="education__button-btn"
            >
                ОФОРМИТЬ ЗАЯВКУ
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "@/lib/axios";

export default {
    props: ["page", "type", "courseId", "courseType", "groupUrl"],
    data() {
        return {
            image: [
                "/img/sert/sert-1-mini.png",
                "/img/sert/sert-2-mini.png",
                "/img/sert/sert-3-mini.png",
            ],
            bigImage: [
                "/img/sert/sert-1.png",
                "/img/sert/sert-2.png",
                "/img/sert/sert-3.png",
            ],
            imageNew: [
                "/img/sert/sert/sert1.png",
                "/img/sert/sert/sert2.png",
                "/img/sert/sert/sert3.png",
            ],
            bigImageNew: [
                "/img/sert/sert/sert1Big.png",
                "/img/sert/sert/sert2Big.png",
                "/img/sert/sert/sert3Big.png",
            ],
            selectedImage: null,
            initialViewIndex: 0,
        };
    },
    methods: {
        selectImage(image, key) {
            this.selectedImage = image;
            this.initialViewIndex = key;
        },
        show() {
            this.$viewerApi({
                images: this.groupUrl ? this.bigImageNew : this.bigImage,
                options: {
                    initialViewIndex: this.initialViewIndex,
                    title: false,
                    inline: true,
                    button: true,
                    navbar: false,
                    toolbar: {
                        prev: true,
                        next: true,
                    },
                    tooltip: true,
                    movable: false,
                    zoomable: false,
                    rotatable: true,
                    scalable: true,
                    transition: true,
                    fullscreen: true,
                    keyboard: true,
                },
            });
        },
    },
};
</script>

<style lang="sass">
@import "../../assets/sass/components/education-detail"

.education__button
    display: block
    text-align: left

    &-btn
        min-width: 275px
        text-align: center
        display: inline-block
        background: #d2233c
        border-radius: 4px
        color: #fff !important
        text-transform: uppercase
        font: 700 16px/24px Futuris
        margin-top: 20px
        padding: 10px
        text-decoration: none
        cursor: pointer

        &:hover
            text-decoration: none
</style>
